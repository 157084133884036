"use client";
import { signIn } from "next-auth/react";
import { useSearchParams } from "next/navigation";
import { useEffect } from "react";

export const Impersonation = () => {
  const searchParams = useSearchParams();
  useEffect(() => {
    if (searchParams.get("impersonate")) {
      signIn("costar", { callbackUrl: "/" });
    }
  });
  return <></>;
};
