"use client";

import { PropsWithChildren, useEffect, useRef } from "react";
import { usePathname } from "next/navigation";
import { useTrackInteractions } from "./hooks/use-track-interactions";
import { trackEventApi } from "./hooks/track-event-api";
import { ClientTrackingEvent } from "../helpers/event-tracking-helpers";
import { getMetrics } from "./client-performance";

export const EventTracking = (props: PropsWithChildren) => {
  const pathname = usePathname();
  useEffect(() => {
    trackEventApi(
      {
        event: ClientTrackingEvent.PAGE_VIEWED,
      },
      pathname
    );
  }, [pathname]);

  useEffect(() => {
    (() => {
      const metrics = getMetrics();

      trackEventApi(
        {
          event: ClientTrackingEvent.PERFORMANCE,
          metrics,
        },
        pathname
      );
    })();
  }, [pathname]);
  const ref = useRef(null);
  useTrackInteractions(ref, pathname);
  return (
    <div ref={ref} className="h-full">
      {props.children}
    </div>
  );
};
