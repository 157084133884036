"use client";

import { useEffect } from "react";
import { trackEventApi } from "./track-event-api";
import { IEventTrackingBody } from "../../../services/track-event/track-event-service";

export const useTrackInteractions = (
  node: React.RefObject<HTMLElement>,
  pathName: string
) => {
  useEffect(() => {
    if (node?.current) {
      const onClick = (e: Event) => {
        if (e.target) {
          const target = e.target as HTMLElement;
          const interactiveElement: HTMLAnchorElement | null =
            target.closest("[data-track-click]");
          if (interactiveElement) {
            const details = interactiveElement.dataset.trackDetails;
            try {
              const eventDetails: IEventTrackingBody = details
                ? {
                    ...JSON.parse(details),
                    ...(interactiveElement.href && {
                      clickedLink: interactiveElement.href,
                    }),
                  }
                : {};
              // console.log("***** HERE?", pathName, eventDetails, node.current);
              trackEventApi(eventDetails, pathName); //here
            } catch (e) {}
          }
        }
      };
      node.current.addEventListener("click", onClick);

      return function cleanup() {
        node?.current?.removeEventListener("click", onClick);
        // console.log("*** removing event", node?.current);
      };
    }
  }, [node, pathName]);
};
