// Necessary if using App Router to ensure this file runs on the client
"use client";
import {
  HTTP_UNAUTHORIZED,
  HTTP_PRECONDITION_FAILED,
  setRedirectOnce,
  NEXTCLIENTRSCREQUEST,
} from "@/middlewares/utils";

if (typeof window !== "undefined") {
  const { fetch: originalFetch } = window;
  window.fetch = async (...args) => {
    const [resource, config] = args;

    // add client-request: true to each client fetch
    const headers = new Headers(config?.headers);

    if (resource instanceof URL && resource?.searchParams?.has("_rsc")) {
      headers.set(NEXTCLIENTRSCREQUEST, "true");
    }
    const response: Response = await originalFetch(resource, {
      ...config,
      headers,
    });

    const reason = response?.headers.get("reason");
    if (
      !response.ok &&
      (response.status === HTTP_UNAUTHORIZED ||
        response.status === HTTP_PRECONDITION_FAILED)
    ) {
      const signoutUrl =
        !!reason &&
        `${window.location.origin}/auth/logout?r=${encodeURIComponent(reason)}`;
      setRedirectOnce(signoutUrl);
      return Promise.reject(response);
    }
    return response;
  };
}

export default function PatchFetch() {
  // Render nothing - this component is only included so that the code
  // above will run client-side

  return null;
}
