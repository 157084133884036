export interface IMetrics {
  timeToFirstByte: number;
  loadTime: number;
  domContentLoadedTime: number;
  firstPaint: number;
  firstContentfulPaint: number;
  navigationStartTime: number;
  connectTime: number;
  cdnCacheStatus: string;
}

export const getMetrics = () => {
  const paintEntries = window.performance.getEntriesByType(
    "paint"
  ) as PerformancePaintTiming[];
  const navigationTiming = window.performance.getEntriesByType(
    "navigation"
  )?.[0] as PerformanceNavigationTiming;

  if (navigationTiming) {
    const metrics: IMetrics = {
      timeToFirstByte:
        navigationTiming?.responseStart - navigationTiming?.startTime,
      loadTime: navigationTiming?.loadEventEnd - navigationTiming?.startTime,
      domContentLoadedTime:
        navigationTiming?.domContentLoadedEventEnd -
        navigationTiming?.startTime,
      firstPaint:
        paintEntries?.find((entry) => entry.name === "first-paint")
          ?.startTime ?? 0,
      firstContentfulPaint:
        paintEntries?.find((entry) => entry.name === "first-contentful-paint")
          ?.startTime ?? 0,
      navigationStartTime: navigationTiming?.startTime,
      connectTime:
        navigationTiming?.connectEnd - navigationTiming?.connectStart,
      cdnCacheStatus:
        navigationTiming?.serverTiming?.find(
          (entry) => entry.name === "cdn-cache"
        )?.description ?? "",
    };

    return metrics;
  }
};
