export const HTTP_PRECONDITION_FAILED = 412;
export const HTTP_UNAUTHORIZED = 401;
export const NEXTCLIENTRSCREQUEST = "next-client-rsc-request";

const setRedirect = (redirectUrl: string) => {
  window.location.href = redirectUrl;
};

// WARNING: This is not a drop in replacement solution and
// it might not work for some edge cases. Test your code!
const once = (fn: (arg: string) => void) => {
  let called = false;
  let result: unknown;
  return (...args: unknown[]) => {
    if (!called) {
      //@ts-expect-error unknown
      result = fn(...args);
      called = true;
    }
    return result;
  };
};

export const setRedirectOnce = once(setRedirect);

export const reasons = [
  "bumped-out",
  "timed-out",
  "timed-out-max-duration",
  "terminated",
  "not-found",
  "revoked-unknown",
];
